/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header, { NavProps } from "./header"

import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import "./layout.css"

interface LayoutProps extends React.HTMLProps<HTMLDivElement> {};

/*
<footer class="mt-auto text-white-50">
  <p>Cover template for <a href="https://getbootstrap.com/" class="text-white">Bootstrap</a>, by <a href="https://twitter.com/mdo" class="text-white">@mdo</a>.</p>
</footer>
*/
const Footer = () => (
  <footer className="mt-auto text-white-50">
    <p>© {new Date().getFullYear()}, Cereb.io</p>
  </footer>
);

// <footer
// style={{
//   marginTop: `2rem`,
// }}
// >
// © {new Date().getFullYear()}, Built with
// {` `}
// <a href="https://www.gatsbyjs.com">Gatsby</a>
// </footer>

const Layout = ({ children, ...props }: LayoutProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const navLinks: NavProps[] = [
    {to: "/", children: "Home"},
  ]; 

  return (
    <div className="d-flex h-100">
      <div {...props}>
        <Header 
          className="mb-auto"
          siteTitle={data.site.siteMetadata?.title || `Title`} 
          navLinks={navLinks}
        />

        {children}
        {/* <main></main> */}
        
        <Footer />
      </div>
    </div>
  )
}

export default Layout
