import * as React from "react"
import { Link, GatsbyLinkProps } from "gatsby"

import { withClass } from "../utils/classUtils";

import "./header.css";

/*
<header class="mb-auto">
    <div>
      <h3 class="float-md-start mb-0">Cover</h3>
      <nav class="nav nav-masthead justify-content-center float-md-end">
        <a class="nav-link active" aria-current="page" href="#">Home</a>
        <a class="nav-link" href="#">Features</a>
        <a class="nav-link" href="#">Contact</a>
      </nav>
    </div>
  </header>
*/

export interface NavProps extends Omit<GatsbyLinkProps<{}>, 'ref'> {};

export interface HeaderProps extends React.HTMLProps<HTMLElement> {
  siteTitle: string;
  navLinks: NavProps[];
};

const Header = ({ siteTitle, navLinks, ...headerProps }: HeaderProps) => (
  <header {...withClass(headerProps, "text-white")}>
    <div>
      <h3 className="float-md-start mb-0">
          {siteTitle}
      </h3>
      <nav className="nav nav-masthead justify-content-center float-md-end">
        {/* Should `class="active" and aria-current="page"` be added in here somehow? */}
        {navLinks.map(({ children, ...navProps}: NavProps) => (
          <Link {...withClass(navProps, "nav-link")}>{children}</Link>
        ))}
        <a className="nav-link" href={"https://blog.cereb.io"}>Blog</a>
      </nav>
    </div>
  </header>
)

export default Header
